<template>
  <div class="shopMember">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>统计分析</el-breadcrumb-item>
      <el-breadcrumb-item>运营趋势</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="shop-screen">
      <el-form ref="searchForm" :inline="true" :model="searchForm" size="small" label-width="80px">
        <el-form-item label="时间" prop="time">
          <el-date-picker
            v-model="searchForm.time"
            style="width:230px"
            :disabled="true"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
      </el-form>
    </div>

    <div class="echarts-title" style="margin-top:30px;">盒子上架和售出数</div>
    <el-button
      type="primary"
      icon="el-icon-download"
      style="margin-left:30px;margin-top:30px;"
      @click="doExport23"
    >
      盒子上架和售出数导出
    </el-button>
    <div class="echarts-title-num">
      累计上架{{ canvasData.up_sell_number.up_total }},
      售出{{ canvasData.up_sell_number.sell_total }};
      平均每天上架{{ canvasData.up_sell_number.up_average }},
      售出{{ canvasData.up_sell_number.sell_average }}。
    </div>
    <div class="echarts-content"><CoreChart1 :canvas-data="canvasData" /></div>

    <div class="echarts-title" style="margin-top:30px;">动销比</div>
    <el-button
      type="primary"
      icon="el-icon-download"
      style="margin-left:30px;margin-top:30px;"
      @click="doExport24"
    >
      动销比导出
    </el-button>
    <div class="echarts-title-num">
      最高动销{{ canvasData.up_sell_number.proportion_max }}%,
      最低动销{{ canvasData.up_sell_number.proportion_min }}%,
      平均动销{{ canvasData.up_sell_number.proportion_average }}%。
    </div>
    <div class="echarts-content"><CoreChart2 :canvas-data="canvasData" /></div>

    <div class="echarts-title" style="margin-top:30px;">门店访问次数</div>
    <el-button
      type="primary"
      icon="el-icon-download"
      style="margin-left:30px;margin-top:30px;"
      @click="doExport25"
    >
      门店访问次数导出
    </el-button>
    <div class="echarts-title-num">
      累计访问{{ canvasData.visit_number.page_views_total }}次,
      平均每天访问{{ canvasData.visit_number.page_views_average }}次。
    </div>
    <div class="echarts-content"><CoreChart3 :canvas-data="canvasData" /></div>

    <div style="width:100%;height:300px;" />
  </div>
</template>

<script>
import {getCoreData} from "@/api/goods.js"
import { httpUrl } from "@/utils/http.js"
import axios from "axios"
import dayjs from 'dayjs'
import CoreChart1 from './components/CoreChart-num1'
import CoreChart2 from './components/CoreChart-num2'
import CoreChart3 from './components/CoreChart-num3'
export default {
  components: { CoreChart1, CoreChart2, CoreChart3 },
  data() {
    return {
      searchForm: {
        time: []
      },
      filename1:'盒子上架和售出数',
      filename2:'动销比',
      filename3:'门店访问次数',
      canvasData: {
        remind_number: {},
        visit_number: {},
        up_sell_number: {}
      },
      loading: true
    }
  },
  created(){
    this.searchForm.time[0] = new Date(dayjs(new Date()).subtract(2, 'month').format())
    this.searchForm.time[1] = new Date()
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      const data = {
        time_start: dayjs(this.searchForm.time[0]).format('YYYY-MM-DD'),
        time_end: dayjs(this.searchForm.time[1]).format('YYYY-MM-DD')
      }
      let res=await getCoreData({...data})
      if (res.data.code == 0) {
        this.loading = false
        this.canvasData = res.data.data || {}
        this.canvasData.id = +new Date() || ''
        this.canvasData.remind_number = this.canvasData.remind_number || {}
        this.canvasData.visit_number = this.canvasData.visit_number || {}
        this.canvasData.up_sell_number = this.canvasData.up_sell_number || {}
      }
    },
    doExport23() {
      const loading = this.$loading({
        lock: true,
        text: '导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
        const time_start= this.searchForm.time.length>0?dayjs(this.searchForm.time[0]).format('YYYY-MM-DD'):''
        const time_end= this.searchForm.time.length>0?dayjs(this.searchForm.time[1]).format('YYYY-MM-DD'):''
      axios
        .get(
          `${httpUrl}api/v2/chain/getShopUpAndDownExport?time_start=${time_start}&time_end=${time_end}`,
          {
            responseType: "blob", //服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'，默认是'json'
          }
        )
        .then((res) => {
          if (!res) return;
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          }); // 构造一个blob对象来处理数据，并设置文件类型

          if (window.navigator.msSaveOrOpenBlob) {
            //兼容IE10
            navigator.msSaveBlob(blob, this.filename1);
          } else {
            const href = URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载链接
            a.download = this.filename1; //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
             loading.close()
          }
          // 这里也可以不创建a链接，直接window.open(href)也能下载
        })
        .catch((err) => {
          this.$message.error('导出失败,请重试!')
          loading.close()
        })
    },
    doExport24() {
      const loading = this.$loading({
        lock: true,
        text: '导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
        const time_start= this.searchForm.time.length>0?dayjs(this.searchForm.time[0]).format('YYYY-MM-DD'):''
        const time_end= this.searchForm.time.length>0?dayjs(this.searchForm.time[1]).format('YYYY-MM-DD'):''
      axios
        .get(
          `${httpUrl}api/v2/chain/getShopUpAndDownExport?time_start=${time_start}&time_end=${time_end}`,
          {
            responseType: "blob", //服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'，默认是'json'
          }
        )
        .then((res) => {
          if (!res) return;
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          }); // 构造一个blob对象来处理数据，并设置文件类型

          if (window.navigator.msSaveOrOpenBlob) {
            //兼容IE10
            navigator.msSaveBlob(blob, this.filename2);
          } else {
            const href = URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载链接
            a.download = this.filename2; //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
             loading.close()
          }
          // 这里也可以不创建a链接，直接window.open(href)也能下载
        })
        .catch((err) => {
          this.$message.error('导出失败,请重试!')
          loading.close()
        })
    },
    doExport25() {
      const loading = this.$loading({
        lock: true,
        text: '导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
        const time_start= this.searchForm.time.length>0?dayjs(this.searchForm.time[0]).format('YYYY-MM-DD'):''
        const time_end= this.searchForm.time.length>0?dayjs(this.searchForm.time[1]).format('YYYY-MM-DD'):''
      axios
        .get(
          `${httpUrl}api/v2/chain/getShopVisitNumberExport?time_start=${time_start}&time_end=${time_end}`,
          {
            responseType: "blob", //服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'，默认是'json'
          }
        )
        .then((res) => {
          if (!res) return;
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          }); // 构造一个blob对象来处理数据，并设置文件类型

          if (window.navigator.msSaveOrOpenBlob) {
            //兼容IE10
            navigator.msSaveBlob(blob, this.filename3);
          } else {
            const href = URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载链接
            a.download = this.filename3; //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
             loading.close()
          }
          // 这里也可以不创建a链接，直接window.open(href)也能下载
        })
        .catch((err) => {
          this.$message.error('导出失败,请重试!')
          loading.close()
        })
    },
  }
}
</script>

<style lang="less" scoped>
  .shopMember {
    width: 100%;
    height: 100%;
    background: #eee;
    box-sizing: border-box;
    padding: 30px;
  }
  .shop-screen {
    background: #ffffff;
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
  }

  .echarts-title{
    box-sizing: border-box;
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid #606266;
    border-radius: 10px;
  }
  .echarts-title-num{
    color: #999;
    font-size: 13px;
    margin-top: 20px;
  }
  .echarts-content{
    margin-top: 20px;
  }
</style>